import * as React from 'react'
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/solid'

type AlertProps = {
  title: string
  message: string
  isError?: boolean
}

export default function Alert({ title, message, isError }: AlertProps) {
  return (
    <div
      className={'rounded-md p-4 ' + (isError ? 'bg-red-50' : 'bg-green-50')}
    >
      <div className="flex">
        <div className="flex-shrink-0">
          {isError ? (
            <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
          ) : (
            <CheckCircleIcon
              className="h-5 w-5 text-green-400"
              aria-hidden="true"
            />
          )}
        </div>
        <div className="ml-3">
          <h3
            className={
              'text-sm font-medium ' +
              (isError ? 'text-red-800' : 'text-green-800')
            }
          >
            {title}
          </h3>
          <div
            className={
              'mt-2 text-sm ' + (isError ? 'text-red-700' : 'text-green-700')
            }
          >
            <p>{message}</p>
          </div>
        </div>
      </div>
    </div>
  )
}
